@import 'react-big-calendar/lib/css/react-big-calendar.css';

.rbc-time-header {
  /* padding-bottom: 100px; */
  font-size: 20px;

  /* border: 2px solid red; */
}

.rbc-row-content {
  position: absolute;
  border: none;
}

.rbc-header {
  overflow: initial;
  -webkit-box-flex: 1;
  -ms-flex: 0 0 0%;
  flex: 1 1 0%;
  text-overflow: ellipsis;
  padding: 5px;
  text-align: center;
  vertical-align: baseline;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border: none;
  /* background-color: red; */
}

.rbc-header + .rbc-header {
  border: none;
}

/* .rbc-time-header.rbc-overflowing {
  border: 1px solid #ddd;
} */

.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.rbc-time-header-content {
  border-left: none;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border: none;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.rbc-timeslot-group {
  border-right: 1px dashed #ddd;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid red;
}

.rbc-time-view,
.rbc-time-content {
  border: none;
}

.rbc-time-column.rbc-time-gutter .rbc-timeslot-group {
  border: none;
  color: #bfbfbf;
  font-weight: 600;
  font-size: 15px;
}

.rbc-today {
  background-color: transparent;
}

.rbc-timeslot-group {
  min-height: 120px;
  border-top: 1px dashed #ddd;
}

.rbc-time-content::-webkit-scrollbar {
  width: 8px;
}

.rbc-time-content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #959595;
}

.rbc-time-content > * + * > * {
  border-left: none;
}
