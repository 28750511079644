#inputID::placeholder {
  color: #ff0000;
  opacity: 1;
}

#inputID {
  color: #e2e8f0;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
